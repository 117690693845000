<template>
  <main v-if="relics" class="threeDimensionsDetail">
    <BaseBanner style="padding: 9.2rem 0;" />
    <section class="container threeDimensionsDetail__section">
      <h2 class="threeDimensionsDetail__title">{{ relics.titleZh }}</h2>
      <hr class="threeDimensionsDetail__hr" />
      <div class="threeDimensionsDetail__content">
        <div class="threeDimensionsDetail__main">
          <div
            v-if="!relics.url.includes('sketchfab')"
            class="threeDimensionsDetail__img"
            :style="`background-image: url(${relics.url})`"
          ></div>

          <div v-else class="threeDimensionsDetail__img">
            <iframe
              :title="relics.titleZh"
              frameborder="0"
              allowfullscreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-tracking
              execution-while-out-of-viewport
              execution-while-not-rendered
              web-share
              :src="relics.url"
              class="threeDimensionsDetail__iframe"
            >
            </iframe>
          </div>

          <div class="threeDimensionsDetail__info">
            <p v-if="relics.authorZh" class="threeDimensionsDetail__infoText">
              <span class="threeDimensionsDetail__textTitle">作者</span
              >{{ relics.authorZh }}
            </p>
            <p class="threeDimensionsDetail__infoText">
              <span class="threeDimensionsDetail__textTitle">尺寸</span>
              {{ relics.size }}
            </p>
            <p class="threeDimensionsDetail__infoText">
              <span class="threeDimensionsDetail__textTitle">材質</span>
              {{ relics.materialZh }}
            </p>
          </div>
        </div>
        <div class="threeDimensionsDetail__text">
          <p>
            {{ relics.introZh }}
          </p>
          <br />
          <p class="threeDimensionsDetail__text--en">
            {{ relics.introEn }}
          </p>
        </div>
      </div>
      <hr class="threeDimensionsDetail__hr" />
    </section>
    <div class="threeDimensionsDetail__bg"></div>
    <GoBackFooter
      :routeItem="{
        text: '返回3D文物檢索',
        arrowString: '&#10229;',
        routeObj: { name: 'Publication3D' },
        useHistory: true
      }"
    />
  </main>
</template>

<script>
import BaseBanner from '@/components/BaseBanner.vue'
import GoBackFooter from '@/components/GoBackFooter.vue'

// Api
import { apiGetRelics } from '@/api/webAPI'

export default {
  components: { BaseBanner, GoBackFooter },
  data() {
    return {
      relics: null
    }
  },
  methods: {
    async fetchData() {
      const { id } = this.$route.query
      if (!id) this.$router.push({ name: 'Publication3D' })

      const relicsResponse = (
        await apiGetRelics({
          id
        })
      ).data.data

      if (relicsResponse.length === 0) {
        this.$router.push({ name: 'Publication3D' })
        return
      }

      this.relics = relicsResponse[0].relics[0]
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style lang="scss">
.threeDimensionsDetail {
  &__section {
    margin-top: -12.9rem;
    position: relative;
  }

  &__title::before {
    content: '';
    background-image: url('~@/assets/image/TitleIcon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 2.8rem;
    height: 1.9rem;
    display: inline-block;
    margin-right: 0.6rem;
  }

  &__title {
    color: #595757;
    font-weight: 500;
    line-height: 124%;
    letter-spacing: 0.18em;
    font-size: 2.4rem;
  }

  &__hr {
    border: none;
    border-bottom: 1px solid #d0a805;
    width: 100%;
    margin: 4rem 0;
  }

  &__content {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    width: 62%;
  }

  &__text {
    width: 38%;
    padding-left: 4.2rem;
    font-size: 1.6rem;
    line-height: 140%;
    letter-spacing: 0.12em;
    text-align: justify;
  }

  &__text--en {
    font-size: 1.5rem;
  }

  &__img {
    width: 100%;
    padding-bottom: 66.717%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  &__info {
    padding-top: 2.5rem;
  }

  &__infoText::before {
    content: '';
    width: 1rem;
    height: 0.3rem;
    display: inline-block;
    background-color: #d0a805;
    margin-bottom: 0.35rem;
    margin-right: 0.9rem;
  }

  &__infoText {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 160%;
    color: #595757;
  }

  &__bg {
    width: 100%;
    padding-bottom: 9.5%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('~@/assets/image/ThreeDimensionsDetail/Background.jpg');
    margin-top: 1.4rem;
  }

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &__textTitle {
    margin-right: 1.8rem;
  }
}

@media (max-width: 768px) {
  .threeDimensionsDetail {
    &__content {
      flex-wrap: wrap;
    }

    &__main,
    &__text {
      width: 100%;
    }

    &__main {
      margin-bottom: 2rem;
    }

    &__text {
      padding: 0;
    }
  }
}
</style>
